export default {
    filters: {
        planLink: function (value, planUrl) {
            return planUrl + "detail.html?p=" + value;
        },
    },

    methods: {
    },
};



