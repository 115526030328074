'use strict';

import Vue from 'vue'
import commonMixin from '../mixins/common-mixin';
import configMixin from '../mixins/config-mixin';
import Utils from '../modules/utils';
import Calendar from '../modules/calendar';
import calendarAndPickupComponent from './components/calendar-and-pickup-component.vue';
import pickupPlanComponent from './components/pickup-plan-component.vue';
import pickupComponent from './components/pickup-component.vue';

var fairvm = new Vue({
    el: '#vue_element_fair',

    data: {
        id: null,
        imageUrl: null,
        fairUrl: null,
        startWeek: 1,

        pickup: null,

        calendar: null,
        calendarData: null,
    },

    mixins: [
        commonMixin,
    ],

    components: {
        'calendar-and-pickup-component': calendarAndPickupComponent,
    },
});

var planvm = new Vue({
    el: '#vue_element_plan',

    data: {
        id: null,
        imageUrl: null,
        planUrl: null,

        pickupPlan: null,
    },

    mixins: [
        commonMixin,
    ],


    components: {
        'pickup-plan-component': pickupPlanComponent,
    },
});

var pickupvm = new Vue({
    el: '#vue_element_fair_pickup',

    data: {
        id: null,
        imageUrl: null,
        fairUrl: null,
        startWeek: 1,

        pickup: null,
    },

    mixins: [
        commonMixin,
    ],

    components: {
        'pickup-component': pickupComponent,
    },
});


var vm = new Vue({
    data: {
        id: null,
        apiUrl: null,
        imageUrl: null,
        fairUrl: null,
        planUrl: null,
        cookieDomain: null,
        startWeek: 1,
    },

    mixins: [
        commonMixin,
        configMixin,
    ],

    components: {
    },

    methods: {
        loadFair: function () {
            var this_ = this;

            this.request(this.apiUrl + '/calendar/' + this.id + '.json')
            .then(function(res) {
                if (res.status != 200) {
                    console.log(res);
                    //alert('Api system error occured.');
                    return;
                }

                if (res.data.res != 1) {
                    console.log(res);
                    //alert('Api system error occured.');
                    return;
                }

                Vue.set(fairvm, "pickup", res.data.body.pickup);
                Vue.set(planvm, "pickupPlan", res.data.body.plan_pickup);
                Vue.set(fairvm, "calendarData", res.data.body.calendar);

                Vue.set(fairvm, 'calendar', 
                    Calendar.create(undefined, undefined, this_.startWeek));

                Vue.set(pickupvm, "pickup", res.data.body.pickup);

            }).catch(function(error){
                alert(error);
            });
        },

        setChildConfig: function () {
            Vue.set(fairvm, "id", this.id);
            Vue.set(fairvm, "fairUrl", this.fairUrl);
            Vue.set(fairvm, "imageUrl", this.imageUrl);
            Vue.set(fairvm, "startWeek", this.startWeek);

            Vue.set(pickupvm, "id", this.id);
            Vue.set(pickupvm, "fairUrl", this.fairUrl);
            Vue.set(pickupvm, "imageUrl", this.imageUrl);
            Vue.set(pickupvm, "startWeek", this.startWeek);

            Vue.set(planvm, "id", this.id);
            Vue.set(planvm, "planUrl", this.planUrl);
            Vue.set(planvm, "imageUrl", this.imageUrl);
        }
    },
    created: function() {
        this.loadBaseConfig();
        this.loadPlanConfig();
        this.setChildConfig();
        Utils.setAc(this.cookieDomain);

        this.loadFair();
    },
});


