<template>
<div class="column-2" id="bridalPlan" v-if="pickupPlan" v-cloak>
<a :href="pickupPlan.plan_code | planLink(planUrl)">
<h3>PLAN</h3>
<div class="photo"><img :src="pickupPlan.mainImage | inUpdate(imageUrl, id)" alt="" /></div>
<dl>
<dt>{{ pickupPlan.planNm }}</dt>
<dd>{{ pickupPlan.special }}</dd>
<dd class="btnDetail"><span>このプランの詳細へ</span></dd>
</dl>
</a>
</div><!--//column-2-->
</template>

<script>
import commonMixin from '../../mixins/common-mixin';
import planMixin from '../../mixins/plan-mixin';

export default {
    data: function () {
        return {
        }
    },

    props: [
        'id',
        'planUrl',
        'imageUrl',
        'pickupPlan'
    ],

    mixins: [
        commonMixin,
        planMixin,
    ],

    methods: {
    },

    created: function () {
    },
};
</script>
