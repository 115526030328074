var Cookies = require('js-cookie');
var Moment = require("moment");
require('moment/locale/ja');
require('url-search-params-polyfill');

export default {
    inUpdate: function (v, imgUrl, id) {
        return imgUrl + 'update/' + id + '/jpg/' + v;
    },

    imageUrl: function (v, imgUrl, id) {
        return (window.location.href.substr(0, 5) == 'https' ? 'https' : 'http') +
        '://' +
        window.location.hostname + 
        this.inUpdate(v, imgUrl, id);
    },

    getAc: function () {
        return Cookies.get('acid');
    },

    setAc: function (cookieDomain) {
        var search, acid;

        search = new URLSearchParams(window.location.search);
        acid = search.get('cid');
        if (typeof acid == 'undefined' || acid == null || acid.length < 1) {
            return;
        }

        Cookies.set('acid', acid, {
                path: '/', 
                domain: cookieDomain,
                expires: new Date(new Date().getTime() + 12 * 60 * 60 * 1000)
        });
    },

    getAcParams: function () {
        var acid = this.getAc(), prmAdv, prmSite;
        prmAdv = typeof acid == 'string' && acid != "" ? acid : 'Unspecified';

        if (typeof acid !== 'undefined' && acid !== '') {
            if (acid.indexOf('wp') === 0) {
                prmSite = '001';
            } else {
                var test = acid.substring(0, 3);
                prmSite = !isNaN(test) ? test : null;
            }
        } 

        return {
            prmAdv: prmAdv,
            prmSite: prmSite
        };
    },


    reservationUrl: function (url, fairCode, title, subTitle, imageUrl, dateStr) {
        var params, url;
        params = {
            prmFairId: fairCode,
            prmTtl: encodeURIComponent(title),
            prmSubTtl: subTitle,
            prmImgUrl: encodeURIComponent(imageUrl),
            prmDate: encodeURIComponent(dateStr),
        };

        var p = this.getAcParams();
        params.prmAdv = p.prmAdv;
        if (typeof p.prmSite != 'undefined' && p.prmSite !== null) {
            params.prmSite = p.prmSite;
        }

        url = url + '&';

        Object.keys(params).forEach(function (k) {
            url += '&' + k + '=' + this[k];
        }, params);

        return url;
    },

    wday: function (v) {
        v = Number(v);
        switch (v) {
            case 1:
            return "月";
            case 2:
            return "火";
            case 3:
            return "水";
            case 4:
            return "木";
            case 5:
            return "金";
            case 6:
            return "土";
            case 0:
            return "日";
        }

        return '';
    },

    wdayEn: function (v) {
        v = Number(v);
        switch (v) {
            case 1:
            return "Mon";
            case 2:
            return "Tue";
            case 3:
            return "Wed";
            case 4:
            return "Thu";
            case 5:
            return "Fri";
            case 6:
            return "Sat";
            case 0:
            return "Sun";
        }

        return '';
    },


    enMonth: function (v) {
        v = Number(v);
        switch (v) {
            case 1:
            return "January";
            case 2:
            return "February";
            case 3:
            return "March";
            case 4:
            return "April";
            case 5:
            return "May";
            case 6:
            return "June";
            case 7:
            return "july";
            case 8:
            return "August";
            case 9:
            return "September";
            case 10:
            return "October";
            case 11:
            return "November";
            case 12:
            return "December";
        }

        return '';
    },

    formatWday: function (v, format, hday, hformat, lang) {
        if (typeof lang == 'undefined') {
            lang = 'jp';
        }

        var fmt = format;
        if (hformat && hday) {
            fmt = hformat;
        }
        return fmt.replace('%s', lang == 'en' ? this.wdayEn(v) : this.wday(v));
    },

    jpNumber: function (v) {
        var v_, ret = '',
            jpNum = ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九"]; 
        v_ = "" + v;
        Array.prototype.forEach.call(v_, function (a) {
            ret += jpNum[Number(a)];
        });
        return ret;
    },

    isDate: function (date) {
        var dt, y, m, d, dt_;
        dt = Moment(date, "YYYY-MM-DD");
        return dt.isValid();
    },

    md: function (date, hday) {
        var dt;
        dt = Moment(date, "YYYY-MM-DD");
        return (dt.month() + 1) + 
            '/' + 
            dt.date() + 
               '(' + this.wday(dt.day()) + (hday ? '・祝' : '') +  ')';
    },

    mdArr: function (date, hday) {
        var dt;
        dt = Moment(date, "YYYY-MM-DD");
        return {
            md: (dt.month() + 1) + '/' + dt.date(),
            wday: this.wday(dt.day()) + (hday ? '・祝' : ''),
            day: dt.day(),
            hday: hday
        };
    },

    dateFormat: function (date, fmt) {
        var dt;
        dt = Moment(date, "YYYY-MM-DD");
        return dt.format(fmt);
    },
};
