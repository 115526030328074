var Moment = require("moment");
require('moment/locale/ja');
export default {
    /*
     * 
     * [
     *  [0, 0, 0, 1, 2, 3, 4], // 0 is empty, value is date, index = wday -1
     *  [5, 6, 7, 8, 9, 10, 11],
     *  ...
     *  [26, 27, 28, 29, 30, 0, 0]
     * ]
     */
    create: function (year, month, startWeek) {
        var startDate, endDate, startWDay, calendar = [], day, 
            lastDay, current, currentDate;
        if (typeof startWeek == "undefined") {
            startWeek = 1;
        }
        if (typeof year == "undefined" || typeof month == "undefined") {
            year = this.getCurrentYear();
            month = this.getCurrentMonth();
        }
        current = Moment();
        currentDate = current.format('YYYY-MM-DD');

        startDate = Moment([year, (month -1), 1]);
        startWDay = startDate.day(); // wday
        endDate = startDate.add(1, 'months').date(0);
        lastDay = endDate.date(); // end day of month

        day = 0;
        for (var row = 0; row < 6; row++) {
            var week = [];
            for (var i = startWeek; i < 7 + startWeek; i++) {
                var currentDay = i % 7;
                if (day == 0 && startWDay == 0 && currentDay != 0) {
                    week.push([0,null,false]);
                } else if (day == 0 && startWDay > currentDay) {
                    week.push([0,null,false]);
                } else if (day >= lastDay) {
                    week.push([0,null,false]);
                } else {
                    day++;
                    var dateStr = '' +
                        year + '-' + 
                        ('00' + month).substr(-2) + '-' + 
                        ('00' + day).substr(-2);
                    week.push([
                            day, 
                            dateStr,
                            currentDate == dateStr
                    ]);
                }
            }
            calendar.push(week);
        }

        return {
            body: calendar,
            year: year,
            month: month,
        };
    },

    getCurrentYear: function () {
        var current;
        current = Moment();

        return current.year();
    },

    getCurrentMonth: function () {
        var current;
        current = Moment();

        return current.month() + 1;
    },
}
