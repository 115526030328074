<template>
<div class="bridalFair column" v-if="pickup" v-cloak>
<a :href="pickup.fair_code | fairLink(fairUrl)">
<dl>
<dt>{{ pickup.fairNm }}</dt>
<dd class="ph"><img :src="pickup.mainImage | inUpdate(imageUrl, id)" alt=""></dd>
<dd class="date" v-for="(d, index) in pickup.date_arr" v-if="index < 1">日程：{{ d.month  }}/{{ d.day }}{{ d.wday | formatWday('（%s）', d.hday, '（%s・祝）') }}ほか</dd>
<dd class="btn"><span>このフェアの詳細を見る</span></dd>
</dl>
</a>
</div>
</template>

<script>
import commonMixin from '../../mixins/common-mixin';

export default {
    data: function () {
        return {
        }
    },

    props: [
        'id',
        'fairUrl',
        'imageUrl',
        'pickup'
    ],

    mixins: [
        commonMixin,
    ],

    methods: {
    },

    created: function () {
    },
};
</script>
