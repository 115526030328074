import Vue from 'vue'
require('url-search-params-polyfill');
var Promise = require('es6-promise').Promise;
var axios = require('axios');
var Moment = require("moment");
require('moment/locale/ja');
import Utils from '../modules/utils';
import Calendar from '../modules/calendar';

export default {
    filters: {
        inUpdate: function (value, imageUrl, id) {
            return Utils.inUpdate(value, imageUrl, id);
        },
        formatWday: function (value, format, hday, hformat, lang) {
            return Utils.formatWday(value, format, hday, hformat, lang);
        },
        jpNumber: function (value) {
            return Utils.jpNumber(value);
        },
        enMonth: function (value) {
            return Utils.enMonth(value);
        },

        fairLink: function (value, fairUrl, date) {
            return fairUrl + "detail.html?f=" + value + (typeof date != 'undefined' ? '&d=' + date : '');
        },
        fairListLink: function (value, fairUrl, file) {
            if (typeof file == "undefined" || !file) {
                file = "list.html";
            }
            return fairUrl + file + "?d=" + value;
        },
        priceUnit: function (price) {
            price = Math.floor(price);
            return price.toLocaleString();
        },
        priceUnitJp: function (price) {
            var tenThousands, fraction;
            tenThousands = Math.floor(price / 10000);
            fraction = price % 10000;

            return (0 + tenThousands > 0 ? tenThousands + '万' : '') 
            + (Math.floor(fraction) > 0 ? Math.floor(fraction) : '');
        },

        dateFormatJp: function (date, fmt) {
            if (!fmt) {
                fmt = 'YYYY年MM月DD日';
            }
            return Utils.dateFormat(date, fmt);
        },

        substringLengthPostfix: function (value, len, postfix) {
            if (typeof value != "string") {
                return "";
            }
            if (typeof len != "number" || !len) {
                return value;
            }
            if (typeof postfix != "string") {
                postfix = "…"
            }
            if (value.length <= len) {
                return value;
            } else {
                return value.substring(0, len) + postfix;
            }
        },
    },

    methods: {
        loadConfig: function () {
            var el, id, apiUrl, imageUrl, reservationUrl, 
                tel, cookieDomain, startWeek;
            el = document.getElementById('___FAIR_CONFIG___');
            if (typeof el == "undefined" || el == null) {
                alert('No Config Block (id=___FAIR_CONFIG__)');
                return;
            }
            id = el.getAttribute('data-id');
            if (typeof id == "undefined" || id === null) {
                alert('No config property: id (data-id property)');
                return;
            }

            apiUrl = el.getAttribute('data-api-url');
            if (typeof apiUrl == "undefined" || apiUrl === null) {
                alert('No config property: api url (data-api-url property)');
                return;
            }

            imageUrl = el.getAttribute('data-image-url');
            if (typeof imageUrl == "undefined" || imageUrl === null) {
                alert('No config property: image url (data-image-url property)');
                return;
            }

            reservationUrl = el.getAttribute('data-reservation-url');
            if (typeof reservationUrl == "undefined" || reservationUrl === null) {
                alert('No config property: reservation url (data-reservation-url property)');
                return;
            }

            tel = el.getAttribute('data-tel');
            if (typeof tel == "undefined" || tel === null) {
                alert('No config property: tel (data-tel property)');
                return;
            }

            cookieDomain = el.getAttribute('data-cookie-domain');
            if (typeof cookieDomain == "undefined" || cookieDomain === null) {
                alert('No config property: cookie domain (data-cookie-domain property)');
                return;
            }

            startWeek = el.getAttribute('data-start-week');
            if (typeof startWeek == "undefined" || startWeek === null) {
                startWeek = 1;
            }

            Vue.set(this, "id", id);
            Vue.set(this, "apiUrl", apiUrl);
            Vue.set(this, "imageUrl", imageUrl);
            Vue.set(this, "reservationUrl", reservationUrl);
            Vue.set(this, "tel", tel);
            Vue.set(this, "cookieDomain", cookieDomain);
            Vue.set(this, "startWeek", Number(startWeek));
        },

        request: function (uri, options) {
            if (typeof options != "undefined") {
                var opt = [];
                Object.keys(options).forEach(function (k) {
                    opt.push(k + "=" + encodeURIComponent(options[k]));
                });
                uri = uri + '?' + opt.join("&");
            }

            return new Promise(function(resolve, reject) {
                axios.get(uri)
                .then(function(res){
                    if (res.status != "200" || 
                        typeof res.data == "undefined" ||
                        !res.data) {
                        throw "No Content";
                    }
                    resolve(res);
                })
                .catch(function(error){
                    reject(error);
                });
            });
        },

        onSubmitReservation: function (fair, event, md, timeSelectIdx) {
            var url = this.reservationUrl;

            if (typeof md == 'undefined' || !md) {
                md = Utils.md(fair.date, fair.hday);
            }
            if (typeof timeSelectIdx == 'undefined') {
                timeSelectIdx = 0;
            }

            var md_ = md.replace(/（.+）/, '').replace(/\(.+\)/, '').split('/');

            window.open(Utils.reservationUrl(
                url,
                fair.fair_code,
                fair.fairNm,
                '',
                Utils.imageUrl(fair.mainImage, this.imageUrl, this.id),
                md_[0] + '月' + md_[1] + '日' + ' ' + 
                event.target.getElementsByTagName('select')[timeSelectIdx].value.replace('：', ' ')
            ), '_blank');
        },
        
        hasPrevCalendar: function (index, year, month) {
            var dt = Moment([year, (month - 1), 1]);
            dt = dt.subtract(1,'months');

            return typeof index[
                dt.format('YYYY-MM')
            ] != 'undefined' ? true : false;
        },

        hasNextCalendar: function (index, year, month) {
            var dt = Moment([year, (month - 1), 1]);
            dt = dt.add(1,'months');
            return typeof index[
                dt.format('YYYY-MM')
            ] != 'undefined' ? true : false;
        },

        isHoliday: function ($date) {
            return typeof this.calendarData.body[$date] != 'undefined' && 
                    this.calendarData.body[$date] ? true : false;
        },

        isToday: function (date) {
            var search, today;
            search = new URLSearchParams(window.location.search);
            today = search.get('__today');

            var dt = today ? new Date(today) : new Date();
            today = dt.getFullYear() + '-' + 
                    ('00' + (dt.getMonth() + 1)).substr(-2, 2) + '-' + 
                    ('00' + dt.getDate()).substr(-2, 2);
            return today == date ? true : false;
        },

        onClickPrevCalendar: function (year, month, calendarName) {
            var dt = Moment([year, (month - 1), 1]);
            dt = dt.subtract(1,'months');
            if (typeof calendarName == "undefined" || !calendarName) {
                calendarName = 'calendar';
            }

            Vue.set(this, calendarName, 
                Calendar.create(dt.year(), 
                dt.month() + 1, 
                this.startWeek));
        },
        
        onClickNextCalendar: function (year, month, calendarName) {
            var dt = Moment([year, (month - 1), 1]);
            dt = dt.add(1,'months');

            if (typeof calendarName == "undefined" || !calendarName) {
                calendarName = 'calendar';
            }

            Vue.set(this, calendarName, 
                Calendar.create(dt.year(), 
                dt.month() + 1, 
                this.startWeek));
        },

        isTargetDate: function (target) {
            if (!this.hasDate) {
                return false;
            }
            return target == this.date ? true : false;
        },

        dateRangeJp: function (date1, date2) {
            var dt1, dt2, y1, y2, m1, m2;

            if (date1) {
                dt1 = Moment(date1, "YYYY-MM-DD");
                y1 = dt1.format('YYYY年');
                m1 = dt1.format('M月');
            }
            if (date2) {
                dt2 = Moment(date2, "YYYY-MM-DD");
                y2 = dt2.format('YYYY年');
                m2 = dt2.format('M月');
            }

            return (dt1 ? y1 + m1 : '') + "〜" + (dt2 ? (y1 != y2 ? y2 : '') + m2 : '');
        },

        locationHref: function () {
          return encodeURIComponent(window.location.href);
        },
    },
};


