<template>
<div id="calender" v-if="pickup || calendar" v-cloak>
    <div class="inner">
        <div class="bridalFair">
            <h3 class="frmSilver">BRIDAL FAIR</h3>
            <div class="column">
                <div class="column-2" id="fairMainPickup" v-if="pickup">
                    <a :href="pickup.fair_code | fairLink(fairUrl)">
                    <div class="photo"><img :src="pickup.mainImage | inUpdate(imageUrl, id)" alt=""></div>
                    <dl>
                    <dt>{{ pickup.fairNm }}</dt>
                    <dd class="date" v-for="(d, index) in pickup.date_arr" v-if="index < 1">日程：{{ d.month  }}/{{ d.day }}{{ d.wday | formatWday('（%s）', d.hday, '（%s・祝）') }}ほか</dd>
                    <dd><span class="btn">このフェアの詳細を見る</span></dd>
                    </dl>
                    </a>
                </div><!--//column-2-->
                <div class="column-2" id="FairCalendar" v-if="calendar">
                    <h3>カレンダーからフェアを選ぶ</h3>
                    <table>
                        <thead>
                            <tr><th colspan="7">{{ calendar.year }}<br><em>{{ calendar.month }}月</em></th></tr>
                        </thead>
                        <tbody>
                            <tr><th>MON</th><th>TUE</th><th>WED</th><th>THU</th><th>FRI</th><th class="sat">SAT</th><th class="sun">SUN</th></tr>
                            <tr v-for="row in calendar.body">
                                <td v-for="(c, index) in row" :class="{sun: isHoliday(c[1]) || typeof calendarData.body[c[1]] != 'undefined' && index == 6, sat: typeof calendarData.body[c[1]] != 'undefined' && index == 5, disabled : c[0] != 0 && typeof calendarData.body[c[1]] != 'undefined'}"><i v-if="c[0] == 0">&nbsp;</i><a v-else-if="typeof calendarData.body[c[1]] != 'undefined'" class="default" :href="c[1] | fairListLink(fairUrl, 'day.html')">{{ c[0] }}</a><span v-else class="default">{{ c[0] }}</span></td>
                            </tr>

                        </tbody>
                    </table>
                    <p>
                        <a v-if="hasPrevCalendar(calendarData.index, calendar.year, calendar.month)" v-on:click.stop.prevent="prevCalendar(calendar.year, calendar.month)" class="prev">PREVIOUS MONTH &lt;&lt;</a>
                        <a v-if="hasNextCalendar(calendarData.index, calendar.year, calendar.month)" v-on:click.stop.prevent="nextCalendar(calendar.year, calendar.month)" class="next">&gt;&gt; NEXT MONTH</a>
                    </p>
                    <div class="disp-sp"><a :href="fairUrl" class="btn">フェア一覧を見る</a></div>
                </div><!--//column-2-->
            </div>
        </div><!--//calender-->
    </div>
</div><!--//bridalFair-->
</template>

<script>
import commonMixin from '../../mixins/common-mixin';

export default {
    data: function () {
        return {
        }
    },

    props: [
        'id',
        'fairUrl',
        'imageUrl',
        'calendar',
        'calendarData',
        'pickup'
    ],

    mixins: [
        commonMixin,
    ],

    methods: {
        prevCalendar: function (year, month) {
            this.$emit('on-click-prev-calendar', year, month);
        },

        nextCalendar: function (year, month) {
            this.$emit('on-click-next-calendar', year, month);
        },
    },

    created: function () {
    },
};
</script>
