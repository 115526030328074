import Vue from 'vue'

export default {
    methods: {
        loadBaseConfig: function () {
            var el, id, apiUrl, imageUrl, fairUrl, 
                cookieDomain, startWeek;
            el = document.getElementById('___FAIR_CONFIG___');
            if (typeof el == "undefined" || el == null) {
                alert('No Config Block (id=___FAIR_CONFIG__)');
                return;
            }
            id = el.getAttribute('data-id');
            if (typeof id == "undefined" || id === null) {
                alert('No config property: id (data-id property)');
                return;
            }

            apiUrl = el.getAttribute('data-api-url');
            if (typeof apiUrl == "undefined" || apiUrl === null) {
                alert('No config property: api url (data-api-url property)');
                return;
            }

            imageUrl = el.getAttribute('data-image-url');
            if (typeof imageUrl == "undefined" || imageUrl === null) {
                alert('No config property: image url (data-image-url property)');
                return;
            }

            fairUrl = el.getAttribute('data-fair-url');
            if (typeof fairUrl == "undefined" || fairUrl === null) {
                alert('No config property: fair url (data-fair-url property)');
                return;
            }

            cookieDomain = el.getAttribute('data-cookie-domain');
            if (typeof cookieDomain == "undefined" || cookieDomain === null) {
                alert('No config property: cookie domain (data-cookie-domain property)');
                return;
            }

            startWeek = el.getAttribute('data-start-week');
            if (typeof startWeek == "undefined" || startWeek === null) {
                startWeek = 1;
            }

            Vue.set(this, "id", id);
            Vue.set(this, "apiUrl", apiUrl);
            Vue.set(this, "imageUrl", imageUrl);
            Vue.set(this, "fairUrl", fairUrl);
            Vue.set(this, "cookieDomain", cookieDomain);
            Vue.set(this, "startWeek", Number(startWeek));
        },

        loadDetailConfig: function () {
            var el, reservationUrl, tel;
            el = document.getElementById('___FAIR_CONFIG___');
            if (typeof el == "undefined" || el == null) {
                alert('No Config Block (id=___FAIR_CONFIG__)');
                return;
            }

            reservationUrl = el.getAttribute('data-reservation-url');
            if (typeof reservationUrl == "undefined" || reservationUrl === null) {
                alert('No config property: reservation url (data-reservation-url property)');
                return;
            }

            tel = el.getAttribute('data-tel');
            if (typeof tel == "undefined" || tel === null) {
                alert('No config property: tel (data-tel property)');
                return;
            }

            planTel = el.getAttribute('data-plan-tel');
            if (typeof tel == "undefined" || tel === null) {
                alert('No config property: tel (data-plan-tel property)');
                return;
            }

            Vue.set(this, "reservationUrl", reservationUrl);
            Vue.set(this, "tel", tel);
            Vue.set(this, "planTel", planTel);
        },

        loadPlanConfig: function () {
            var el, planUrl, planReservationUrl;
            el = document.getElementById('___FAIR_CONFIG___');
            if (typeof el == "undefined" || el == null) {
                alert('No Config Block (id=___FAIR_CONFIG__)');
                return;
            }

            planUrl = el.getAttribute('data-plan-url');
            if (typeof planUrl == "undefined" || planUrl === null) {
                alert('No config property: plan url (data-plan-url property)');
                return;
            }

            planReservationUrl = el.getAttribute('data-plan-reservation-url');
            if (typeof planReservationUrl == "undefined" || planReservationUrl === null) {
                //alert('No config property: reservation url (data-plan-reservation-url property)');
                //return;
            }

            Vue.set(this, "planUrl", planUrl);
            Vue.set(this, "planReservationUrl", planReservationUrl);
        }
    }
};

